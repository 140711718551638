<template>
    <svg width="1600px" height="900px" viewBox="0 0 1600 900" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        
        <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="50%" gradientTransform="translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.980555),translate(-0.500000,-0.500000)" id="radialGradient-3">
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#FFFFFF" stop-opacity="0.466182255" offset="100%"></stop>
        </radialGradient>
        <path d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0" id="path-4"></path>
        <filter x="-101.6%" y="-70.7%" width="303.1%" height="248.3%" filterUnits="objectBoundingBox" id="filter-5">
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Casinos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="LVCC">
            <g id="path-lvcc">
                <g id="Route" stroke-linecap="round" stroke-linejoin="round">
                    <filter id="glow-1" x="-2" y="-2" width="200" height="200">
                        <feGaussianBlur stdDeviation="4"/>
                    </filter>
                    <path filter="url(#glow-1)" stroke="#FFFFFF" stroke-width="6.00000024" d="M834.600005,359.200001 L823.927246,356.056152 L795.202637,356.056152 L796.649902,381.845215 L760,381.845215 L751.067383,379.543945 C739.321615,369.447983 729.630371,364.400001 721.993652,364.400001 C714.356934,364.400001 700.866699,364.129949 681.522949,363.589844 C675.431803,362.940234 669.907064,362.046191 664.94873,360.907715 C657.51123,359.200001 650.294922,360.946289 644.916016,362.268066 C639.537109,363.589844 629.738281,364.45459 616.452637,372.439453 C603.166992,380.424316 596.027344,379.543945 589.145508,379.543945 C584.557617,379.543945 567.310059,378.575521 537.402832,376.638672 L537.402832,450.773438 L559.79248,451.420898 L605.438965,479.034668 L605.438965,492.751953 L592.494141,524.166992 L592.494141,537.880859 C592.831706,543.427734 595.223633,547.785645 599.669922,550.95459 C606.339355,555.708008 609.723633,561.961914 609.723633,569.179199 C609.723633,573.990723 609.723633,596.305664 609.723633,636.124023 C609.677734,642.523112 608.249512,647.148763 605.438965,650.000977 C601.223145,654.279297 596.465332,659.804688 595.03125,666.72168 C594.075195,671.333008 595.798998,675.62028 600.202657,679.583496 C606.617031,684.89843 609.552897,691.445018 609.010254,699.223261 L609.010254,748 L591.200005,748" class="path-glow"></path>
                    <path stroke="#FFFFFF" stroke-width="6.00000024" d="M834.600005,359.200001 L823.927246,356.056152 L795.202637,356.056152 L796.649902,381.845215 L760,381.845215 L751.067383,379.543945 C739.321615,369.447983 729.630371,364.400001 721.993652,364.400001 C714.356934,364.400001 700.866699,364.129949 681.522949,363.589844 C675.431803,362.940234 669.907064,362.046191 664.94873,360.907715 C657.51123,359.200001 650.294922,360.946289 644.916016,362.268066 C639.537109,363.589844 629.738281,364.45459 616.452637,372.439453 C603.166992,380.424316 596.027344,379.543945 589.145508,379.543945 C584.557617,379.543945 567.310059,378.575521 537.402832,376.638672 L537.402832,450.773438 L559.79248,451.420898 L605.438965,479.034668 L605.438965,492.751953 L592.494141,524.166992 L592.494141,537.880859 C592.831706,543.427734 595.223633,547.785645 599.669922,550.95459 C606.339355,555.708008 609.723633,561.961914 609.723633,569.179199 C609.723633,573.990723 609.723633,596.305664 609.723633,636.124023 C609.677734,642.523112 608.249512,647.148763 605.438965,650.000977 C601.223145,654.279297 596.465332,659.804688 595.03125,666.72168 C594.075195,671.333008 595.798998,675.62028 600.202657,679.583496 C606.617031,684.89843 609.552897,691.445018 609.010254,699.223261 L609.010254,748 L591.200005,748" class="path-line"></path>
                </g>
                <g id="Ending-Point" transform="translate(576.800004, 733.000000)">
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="14.6000011" r="14.4000006"></circle>
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="14.6000011" r="9.60000038"></circle>
                    <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="14.6000011" r="4.80000019"></circle>
                </g>
                <image id="Bitmap" x="450" y="680" width="157" height="141" href="@/assets/images/icons/end.png"></image>
                <g id="Starting-Point" transform="translate(819.800004, 332.000000)">
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="27.6000011" r="14.4000006"></circle>
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="27.6000011" r="9.60000038"></circle>
                    <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="27.6000011" r="4.80000019"></circle>
                    <g id="Fill-1">
                        <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
                        <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4"></use>
                    </g>
                </g>
                <text id="Las-Vegas-Convention" font-family="sans-serif" font-size="14.4000006" font-weight="normal" line-spacing="15.6000006" fill="#FFFFFF">
                    <tspan x="862" y="364">Las Vegas Convention Center</tspan>
                </text>
                <rect id="Rectangle" fill="#000000" x="1599" y="899" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="0" y="0" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="1599" y="0" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="0" y="899" width="1" height="1"></rect>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
    name: "LasVegasConventionCenterPath",
    mounted() {
        var path = document.querySelector('.path-line')
        var length = path.getTotalLength()
        path.style.transition = path.style.WebkitTransition = 'none'
        path.style.strokeDasharray = length + ' ' + length
        path.style.strokeDashoffset = length
        path.getBoundingClientRect()
        path.style.transition = path.style.WebkitTransition =
        'stroke-dashoffset 2s ease-in-out'
        path.style.strokeDashoffset = '0'

        var pathGlow = document.querySelector('.path-glow')
        var lengthGlow = pathGlow.getTotalLength()
        pathGlow.style.transition = pathGlow.style.WebkitTransition = 'none'
        pathGlow.style.strokeDasharray = lengthGlow + ' ' + lengthGlow
        pathGlow.style.strokeDashoffset = lengthGlow
        pathGlow.getBoundingClientRect()
        pathGlow.style.transition = pathGlow.style.WebkitTransition =
        'stroke-dashoffset 2s ease-in-out'
        pathGlow.style.strokeDashoffset = '0'
    }
}
</script>

<style lang="scss" scoped>

</style>